<template>
  <div id="stripe">
    <div class="container flex">
      <div class="form">
        <form @submit.prevent="handleSubmit">
          <div id="payment-element"/>
          <!-- <div id="payment-request-button" style="margin-top:10px;"/> -->
          <van-button style="margin-top:10px;" size="large" color="#642EC7" type="primary" native-type="submit">{{$t('common.pay_6')}}</van-button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant';
import { loadStripe } from '@stripe/stripe-js';
import BigNumber from "bignumber.js";
export default {
  data() {
    return {
      stripe: undefined,
		  elements:undefined,
    }
  },
  mounted(){
    this.initStripe()
  },
  methods: {
    async initStripe() {
      const locale = this.$i18n.locale == 'zh-cn' ? 'zh-Hans' : this.$i18n.locale == 'zh-tw' ? 'zh-Hant' : 'en';
      this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY);
      this.elements = this.stripe.elements({
        clientSecret: this.$route.query.clientSecret,
        locale:locale
      })
      const paymentElement = this.elements.create("payment");
      paymentElement.mount("#payment-element");
      // const paymentRequest = this.stripe.paymentRequest({
      //   country: 'HK',
      //   currency: 'hkd',
      //   total: {
      //     label: 'TripRoaming International Holdings Ltd',
      //     amount: Number(BigNumber(this.$route.query.amount).times(100)),
      //   },
      //   requestShipping: false,
      //   displayItems: [
      //       {
      //           amount: Number(BigNumber(this.$route.query.amount).times(100)),
      //           label: this.$route.query.spec,
      //           pending: false,
      //           type: 'final'
      //       }
      //   ]
      // });
      
      // const prButton = this.elements.create('paymentRequestButton', {
      //   paymentRequest,
      // });
      // console.log(paymentRequest);
      // const result = await paymentRequest.canMakePayment();
      // console.log(result);
      // if (result) {
      //   prButton.mount('#payment-request-button');
      // } else {
      //   document.getElementById('payment-request-button').style.display = 'none';
      // }
    },
    async handleSubmit(e) {
      e.preventDefault();
      const return_url = process.env.NODE_ENV === 'development' ? 'http://esim.dev.triproaming.com/mine/order':'https://esim.triproaming.com/mine/order'
      const elements = this.elements
      const {error} = await this.stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: return_url,
        },
         //redirect: 'if_required'如果设置redirect: 'if_required'则不跳转returnUrl
      });
      console.log(error)
      if(error) Toast(error.message) 
    }
  }
}
</script>
<style lang="scss" scoped>
#stripe {
 .form{
  padding: 20px 10px;
  width: 50%;
 }
}
@media(max-width:768px){
  #stripe {
    .form{
      padding: 20px 10px;
      width:100%;
    }
  }
}
</style>